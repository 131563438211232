/*
 * die Idee hier ist, Bootstrap nicht mehr komplett einzubinden, sondern nur Komponenten, die wir auch wirklich benutzen
 * aus dem
 *
 *   bootstrap/js/src
 *
 * Ordner einzubinden.
 *
 * */

// import 'bootstrap'
import 'bootstrap/js/src/dropdown'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/modal'
